import styled from "@emotion/styled";
import Button from "@vds/buttons/dist/cjs/components/Button";
import { FlexColumn, FlexRow, Spacer, SLNotification } from "../../components/core/Utility";
import { Line } from "@vds/lines";
import { useEffect, useState, useRef, useContext } from "react";

import { milestoneDetails } from "../../services/accountService/milestoneInfo";
import { Loader } from "@vds/loaders";
import moment from "moment";
import { getPreferences, setPreferences } from "../../services/userService/getPrivilege";
import { getAllEnabledItems, getPageMetadataById, openTab } from "../../utility/navigation";
import { messageBus } from "../../utility/messageBus";
import { Modal, ModalBody, ModalFooter } from "@vds/modals";
import { UPDATE_QUICK_LINKS } from "../../constants/ApplicationConstants";
import { Body, Title } from "@vds/typography";
import { CheckboxGroup } from "@vds/inputs";
import * as pageList from "../pageList";
import { generate } from "shortid";
import { PrivilegeContext } from "../../context/privilegeContext";

const LargeText = styled.p({
  fontSize: "42px",
  marginTop: "0px",
  marginBottom: "0.5em",
});
const MediumText = styled.p({
  fontSize: "20px",
  marginTop: "0px",
  marginBottom: "10px",
});
const SmallText = styled.p({
  fontSize: "16px",
  marginTop: "0px",
  marginBottom: "10px",
});

const circleDiv = {
  backgroundColor: "black",
  width: "24px",
  height: "24px",
  borderRadius: "50%",
  color: "white",
  paddingTop: "1px",
  paddingBottom: "2px",
  paddingLeft: "7px",
  fontSize: "16px",
  marginRight: "20px",
};

let firstLoad = true;

export default function WelcomePage() {
  const [m1Details, setM1Details] = useState();
  const [m2Details, setM2Details] = useState();
  const [loader, setLoader] = useState(false);
  const [startupLinks, setStartupLinks] = useState([]);
  const [quickLinks, setQuickLinks] = useState([]);
  const [showStartupModal, setShowStartupModal] = useState(false);
  const [showQuickLinkModal, setShowQuickLinkModal] = useState(false);
  const ref = useRef(new Set());
  const quickLinkCheckedItems = useRef();
  const startupCheckedItems = useRef();
  const { notification, setNotification } = useState();
  const { privileges } = useContext(PrivilegeContext);

  const getData = async () => {
    setLoader(true);
    let response = await milestoneDetails();
    let startupLinks = await getPreferences({ prefix: "welcomepage" });
    if (startupLinks.status === 200) {
      startupLinks = startupLinks?.data?.data ?? [];
      try {
        startupLinks.forEach((item) => {
          let page = JSON.parse(item.value);
          let temp = [];
          page.forEach((item) => temp.push(getPageMetadataById(item)));
          switch (item.preference) {
            case "startupLinks":
              if (firstLoad) {
                temp.forEach((item) => openTab(item));
                firstLoad = false;
              }
              setStartupLinks(temp);
              break;
            case "quickLinks":
              setQuickLinks(temp);
              messageBus.trigger(UPDATE_QUICK_LINKS, temp);
          }
        });
      } catch (e) {
        setStartupLinks([]);
        setQuickLinks([]);
      }
    }

    if (response.status === 200) {
      response = response.data.data;
      let m1ModifiedAt = moment.utc(response.m1ModifiedAt).format("MM/DD/YYYY hh:mm A UTC");
      let m2ModifiedAt = moment.utc(response.m2ModifiedAt).format("MM/DD/YYYY hh:mm A UTC");
      setM1Details(`${response.m1Status}  ${m1ModifiedAt}`);
      setM2Details(`${response.m2Status}  ${m2ModifiedAt}`);
    } else {
      setM1Details("--");
      setM2Details("--");
    }
    setLoader(false);
  };

  const saveLinks = () => {
    setLoader(true);
    setShowStartupModal(false);
    const selectedItems = [...ref.current];
    const preferences = {
      prefix: "welcomepage",
      preferences: [
        {
          preference: "startupLinks",
          value: JSON.stringify(selectedItems),
        },
      ],
    };
    setPreferences(preferences)
      .then((response) => {
        if (response.status === 200) {
          setStartupLinks(selectedItems.map((item) => getPageMetadataById(item)));
        } else {
          notify("Error saving startup links", "error");
        }
      })
      .finally(() => {
        setLoader(false);
        ref.current = new Set();
      });
  };

  const saveQuickLinks = () => {
    setLoader(true);
    setShowQuickLinkModal(false);
    const selectedItems = [...ref.current];
    const preferences = {
      prefix: "welcomepage",
      preferences: [
        {
          preference: "quickLinks",
          value: JSON.stringify(selectedItems),
        },
      ],
    };
    setPreferences(preferences)
      .then((response) => {
        if (response.status === 200) {
          const selectedQuickLinks = selectedItems.map((item) => getPageMetadataById(item));
          setQuickLinks(selectedQuickLinks);
          messageBus.trigger(UPDATE_QUICK_LINKS, selectedQuickLinks);
        } else {
          notify("Error saving quick links", "error");
        }
      })
      .finally(() => {
        setLoader(false);
        ref.current = new Set();
      });
  };

  const itemChangeListener = (event) => {
    if (!ref.current.has(event.target.value)) {
      ref.current.add(event.target.value);
    } else {
      ref.current.delete(event.target.value);
    }
  };

  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    if (showStartupModal) {
      for (const item of startupLinks) {
        ref.current.add(item.id);
      }
    } else {
      ref.current.clear();
    }
  }, [showStartupModal]);
  useEffect(() => {
    if (showQuickLinkModal) {
      for (const item of quickLinks) {
        ref.current.add(item.id);
      }
    } else {
      ref.current.clear();
    }
  }, [showQuickLinkModal]);
  useEffect(() => {
    startupCheckedItems.current = new Set();
    for (const item of startupLinks) {
      startupCheckedItems.current.add(item.id);
    }
  }, [startupLinks]);
  useEffect(() => {
    quickLinkCheckedItems.current = new Set();
    for (const item of quickLinks) {
      quickLinkCheckedItems.current.add(item.id);
    }
  }, [quickLinks]);
  const notify = (content, type) => {
    setNotification(<SLNotification key={generate()} type={type} title={content} fullBleed={true} />);
  };
  return (
    <>
      <Loader active={loader} />
      <Modal opened={showStartupModal} disableOutsideClick closeButton={null}>
        <ModalBody>
          <Title size="medium" bold>
            Select items to add
          </Title>
          <Spacer height="10px" />
          <CheckboxGroup
            onChange={itemChangeListener}
            data={getAllEnabledItems()
              .filter((item) => !item.privileges || privileges.has(item.privileges))
              .map((item) => {
                return {
                  // eslint-disable-next-line import/namespace
                  label: item.name,
                  // eslint-disable-next-line import/namespace
                  value: item.id,
                  // eslint-disable-next-line import/namespace
                  selected: startupCheckedItems?.current?.has(item.id),
                };
              })}
          ></CheckboxGroup>
        </ModalBody>
        <ModalFooter>
          <FlexRow>
            <Button onClick={saveLinks}>OK</Button>
            <Spacer width="20px" />
            <Button onClick={() => setShowStartupModal(false)}>Cancel</Button>
          </FlexRow>
        </ModalFooter>
      </Modal>
      <Modal opened={showQuickLinkModal} disableOutsideClick closeButton={null}>
        <ModalBody>
          <Title size="medium" bold>
            Select items to add
          </Title>
          <Spacer height="10px" />
          <CheckboxGroup
            onChange={itemChangeListener}
            data={getAllEnabledItems()
              .filter((item) => !item.privileges || privileges.has(item.privileges))
              .map((item) => {
                return {
                  // eslint-disable-next-line import/namespace
                  label: item.name,
                  // eslint-disable-next-line import/namespace
                  value: item.id,
                  // eslint-disable-next-line import/namespace
                  selected: quickLinkCheckedItems?.current?.has(item.id),
                };
              })}
          ></CheckboxGroup>
        </ModalBody>
        <ModalFooter>
          <FlexRow>
            <Button onClick={saveQuickLinks}>OK</Button>
            <Spacer width="20px" />
            <Button onClick={() => setShowQuickLinkModal(false)}>Cancel</Button>
          </FlexRow>
        </ModalFooter>
      </Modal>
      {notification}
      <FlexColumn  css={{height: "calc(100vh - 45px) !important"}}>
          <div css={{ display: "flex", flexFlow: "row", width: "100%" }}>
            <div css={{ paddingTop: "2%", width: "25%" }}></div>
            <div css={{ paddingTop: "2%", width: "50%" }}>
              <LargeText css={{ fontWeight: "bold" }}>Welcome to Super Libra</LargeText>
              <MediumText css={{ marginBottom: "23px" }}>Let&apos;s get your set up. Follow the steps below for the personalized experience</MediumText>
              <FlexRow>
                <div css={circleDiv}>1</div>
                <MediumText css={{ fontWeight: "bold" }}>Set up your Quick Links</MediumText>
              </FlexRow>
              <SmallText css={{ marginLeft: "60px" }}>Add custom quick links to boost your performance.</SmallText>
              <Button css={{ marginLeft: "60px", marginBottom: "20px" }} secondary onClick={() => setShowQuickLinkModal(true)}>
                Quick Links
              </Button>
              <FlexRow marginLeft="60px" gap="10px" flexWrap="wrap" marginBottom="10px">
                {quickLinks.length > 0 &&
                  quickLinks.map((item, index) => {
                    return (
                      <Button key={index} secondary onClick={() => openTab(item)}>
                        {item.name}
                      </Button>
                    );
                  })}
              </FlexRow>
              <Line type="light" css={{ marginBottom: "20px" }}></Line>
              <FlexRow>
                <div css={circleDiv}>2</div>
                <MediumText css={{ fontWeight: "bold" }}>Set up your Startup Link</MediumText>
              </FlexRow>
              <SmallText css={{ marginLeft: "60px" }}>These items will open automatically when you login.</SmallText>
              <Button css={{ marginLeft: "60px", marginBottom: "10px" }} secondary onClick={() => setShowStartupModal(true)}>
                Start Up
              </Button>
              <FlexRow marginLeft="60px" gap="10px" flexWrap="wrap" marginBottom="10px">
                {startupLinks.length > 0 &&
                  startupLinks.map((item, index) => {
                    return (
                      <Button key={index} secondary onClick={() => openTab(item)}>
                        {item.name}
                      </Button>
                    );
                  })}
              </FlexRow>

              <Line type="light" css={{ marginBottom: "20px" }}></Line>
            </div>
            <div css={{ paddingTop: "2%", width: "25%" }}></div>
          </div>
          <div css={{ flex: 1 }}></div>
          {process.env.REACT_APP_ENV !== "prod" && (
            <FlexColumn alignItems="flex-end">
              <FlexRow>
                <MediumText span css={{ fontWeight: "bold" }}>
                  M1 - &nbsp;
                </MediumText>
                <MediumText span>{m1Details}</MediumText>
              </FlexRow>
              <FlexRow>
                <MediumText span css={{ fontWeight: "bold" }}>
                  M2 - &nbsp;
                </MediumText>
                <MediumText span>{m2Details}</MediumText>
              </FlexRow>
            </FlexColumn>
          )}
      </FlexColumn>
    </>
  );
}
