import { useState, useCallback, useContext, lazy, useEffect } from "react";
import styled from "@emotion/styled";
import { Header, SLInput } from "../../components/core/Controls";
import { Loader } from "@vds/loaders";
import { CheckinContext } from "../../context/checkinContext";
import { CheckEsnStatusService } from "../../services/checkin/CheckEsnStatus";
import { addPage } from "../../utility/navigation";
import { SLNotification } from "../../components/core/Utility/index";
import { Button } from "@vds/buttons";
import { getProperty } from "../../utility/getProperty";
import EditWorkTicket, { EditWorkTicketPage } from "./EditWorkTicket";
import { isEmpty } from "lodash";

const ContentWrapper = styled.div`
  padding: 10px 10px;
`;

export default function CheckEsnStatus({ input, workTicketNr, productID, type = "" }) {
  const { workTicket } = useContext(CheckinContext);
  const [errValue, setErrValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [isValidRequestDt, setIsValidRequest] = useState(false);
  const [workUnitSearchInputValue, setWorkUnitSearchInputValue] = useState("");

  const CheckEsnStatusFunction = useCallback(async () => {
    setIsLoading(true);
    try {
      let checkEsnStatusPayload = {};
      if (isEmpty(type)) {
        checkEsnStatusPayload = {
          WorkTicketId: input?.workTicketNumber,
          ESN: workUnitSearchInputValue,
          ProductId: input.productId,
          Type: input?.workTicketType,
        };
      } else {
        checkEsnStatusPayload = {
          WorkTicketId: workTicketNr,
          ESN: workUnitSearchInputValue,
          ProductId: productID,
          Type: type,
        };
      }

      const response = await CheckEsnStatusService(checkEsnStatusPayload);
      const isValidRequest = getProperty(response, "vtuData.data.IsValidResponse", false);
      const error = getProperty(response, "vtuData.data.ErrorMessage", "");
      const data = getProperty(response, "vtuData.data.VTU", "");

      if (error) {
        setErrValue(error);
        setIsValidRequest(false);
      } else {
        addPage(EditWorkTicketPage.id, { input: data, workTicketNr: isEmpty(type) ? input?.workTicketNumber : workTicketNr, productID: productID });
        setTableData(data);
        setErrValue("");
        setIsValidRequest(true);
      }
    } catch (error) {
      setErrValue(error);
      setIsValidRequest(false);
    } finally {
      setIsLoading(false);
    }
  }, [input, workUnitSearchInputValue]);

  const handleClickCallback = useCallback(() => {
    CheckEsnStatusFunction();
  }, [CheckEsnStatusFunction]);

  useEffect(() => {
    if (!errValue && isValidRequestDt) {
      addPage(EditWorkTicket.id, { input: tableData, workTicketNr: workTicketNr, productID: productID });
    }
  }, [errValue, isValidRequestDt, tableData]);
  return (
    <ContentWrapper>
      <Header>Check ESN Status</Header>
      {errValue && !isValidRequestDt ? (
        <SLNotification
          type="error"
          title
          onCloseButtonClick={() => setErrValue("")}
          subtitle={errValue}
          fullBleed={false}
          has
          context
          menu
          Compose
          inlineTreatment={false}
        />
      ) : (
        <></>
      )}
      {isLoading ? (
        <Loader active={isLoading} />
      ) : (
        <>
          <SLInput
            value={workUnitSearchInputValue}
            onChange={(e) => setWorkUnitSearchInputValue(e.target.value)}
            error={errValue !== ""}
            errorText={errValue}
            type="text"
            label="Search for Vehicle Tracking Units"
            width="50%"
          />

          <Button size="medium" onClick={handleClickCallback}>
            Search
          </Button>
        </>
      )}
    </ContentWrapper>
  );
}
export const CheckEsnStatusPage = {
  name: "Check ESN status",
  id: "CheckEsnStatusPage",
  component: lazy(() => import("./CheckEsnStatus")),
  route: "/checkesnstatuspage",
};
