import { Button } from "@vds/buttons";
import { Grid, Row } from "@vds/grids";
import { SLGridLayout, SLRowLayout, SLNotification } from "../../components/core/Utility";
import { Loader } from "@vds/loaders";
import { Modal, ModalBody, ModalFooter } from "@vds/modals";
import { Body } from "@vds/typography";
import { lazy, useState } from "react";
import { Header } from "../../components/core/Controls";
import { GridComponent } from "../../components/core/Table/GridComponent";
import { CompactCol } from "../../components/core/Utility";
import { COLUMN_WIDTHS } from "../../constants/ApplicationConstants";
import { generate } from "shortid";
import { getDetailsByESN } from "../../services/VTU/swapDevices";
import { addPage } from "../../utility/navigation";
import { SwapDevicesMetadata } from "./SwapDevicesFinal";
const gridConfig = [
  {
    key: "ESN",
    value: "esn",
    isFilterEnabled: true,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: true,
    width: COLUMN_WIDTHS.small,
  },
];
export default function DisplayAccountUnits(data) {
  const [loading, setLoading] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [notification, setNotification] = useState();
  const notify = (content, type) => {
    setNotification(<SLNotification key={generate()} type={type} title={content} fullBleed={true} />);
  };

  const handleHyperLinkClicked = async (selectedData) => {
    setLoading(true);
    let response = await getDetailsByESN(selectedData.esn);
    if (response?.status === 200 && response?.data?.data?.accountNumber) {
      response = response.data;
      addPage(SwapDevicesMetadata.id, {
        esn: selectedData.esn,
        accountId: response.data.accountNumber,
        orderNumber: response.data.orderHash,
        detailsForESN: response.data,
      });
    } else {
      notify(response?.response?.data?.message ?? "Error occurred while displaying ESNs", "error");
    }
    setLoading(false);
  };

  return (
    <>
      <Header>Display Account Units</Header>
      {notification}
      <Loader active={loading} />
      <Modal opened={alertModal} disableOutsideClick closeButton={null}>
        <ModalBody>
          <Body size="large" bold>
            We are currently experiencing difficulty in processing your request. Please try again sometime.
          </Body>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => setAlertModal(false)}>OK</Button>
        </ModalFooter>
      </Modal>
      <SLGridLayout colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}>
        <SLRowLayout>
          <CompactCol>
            <GridComponent
              isExportEnabled={true}
              noDataMessage="No ESNs found"
              rows={data.response}
              hyperLinkClicked={handleHyperLinkClicked}
              columns={gridConfig}
              title="List of ESNs"
            />
          </CompactCol>
        </SLRowLayout>
      </SLGridLayout>
    </>
  );
}
export const DisplayAccountUnitsMetadata = {
  name: "Display Account Units",
  id: "display_account_units",
  component: lazy(() => import("./DisplayAccountUnits")),
  route: "/display_account_units",
};
